import * as React from 'react';
import {
  DateTimeInput,
  Edit,
  regex,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';
import moment from 'moment';
import PickupPointToolbar from './PickupPointToolbar';
import PickupPointTitle from './PickupPointTitle';
import PickupLocationInput from './PickupLocationInput';
import { statusChoices, typeChoices } from './choices';
import { postalCodeRegex, decimalRegex, validateForm } from './rules';

const parseDate = value => (value ? moment(value).format() : null);

const PickupPointEdit = (props) => {
  const notify = useNotify();

  const onSuccess = () => notify('pickupPoint.form.events.updated', 'success');

  return (
    <Edit
      {...props}
      redirect="edit"
      onSuccess={onSuccess}
      mutationMode="pessimistic"
      title={<PickupPointTitle />}
    >
      <SimpleForm validate={validateForm} toolbar={<PickupPointToolbar />}>
        <TextInput source="name" validate={required()} />
        <SelectInput source="status" choices={statusChoices} />
        <SelectInput source="type" choices={typeChoices} validate={[required()]} />
        <TextInput source="providerId" disabled />
        <TextInput source="address.line1" validate={[required()]} />
        <TextInput source="address.line2" />
        <TextInput source="address.postalCode" validate={[required(), regex(postalCodeRegex)]} />
        <TextInput source="address.city" validate={[required()]} />
        <PickupLocationInput source="pickupLocationId" validate={[required()]} />
        <TextInput source="geoLocation.latitude" validate={[regex(decimalRegex)]} />
        <TextInput source="geoLocation.longitude" validate={[regex(decimalRegex)]} />
        <DateTimeInput source="promotionsAvailabilityDate" parse={parseDate} />
        <TextInput source="createdAt" disabled />
      </SimpleForm>
    </Edit>
  );
};

export default PickupPointEdit;
