import React from 'react';
import {
  Box,
  Button,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { useTranslate, ListButton } from 'react-admin';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'baseline',
    gap: '0 20px',
    margin: '20px 0 10px 0',
  },
  resultContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    margin: '30px 0 10px 0',
  },
  buttonContainer: {
    maxWidth: '100px',
  },
  button: {
    height: '40px',
  },
  italic: {
    fontStyle: 'italic',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  pagination: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0 30px',
  },
}));

const HistoricSearchUi = (props) => {
  const {
    data, code, loading, total, canPrev, canNext, page, setPage, isDetail,
  } = props;
  const initialPage = 1;
  const perPage = 10;
  const zero = 0;
  const classes = useStyles();
  const t = useTranslate();

  const isAbleCardToShow = row => (row?.code && row?.loyaltyCardId);
  const isAbleUserToShow = row => (row?.user?.name && row?.user?.lastName && row?.userId);

  const renderCardCell = (row) => {
    if (isAbleCardToShow(row)) {
      if (isDetail === 'users' || isDetail === 'search') {
        const link = `/#/cards/${row?.loyaltyCardId}`;

        return <a className={classes.link} href={link}>{row?.code}</a>;
      }

      return <span>{row?.code}</span>;
    }

    return '';
  };

  const renderUserCell = (row) => {
    if (isAbleUserToShow(row)) {
      if (isDetail === 'cards' || isDetail === 'search') {
        const link = `/#/users/${row?.userId}`;

        return <a className={classes.link} href={link}>{row?.user?.name} {row?.user?.lastName}</a>;
      }

      return <span>{row?.user?.name} {row?.user?.lastName}</span>;
    }

    return <span className={classes.italic}>{t('cards.history.list.deletedUser')}</span>;
  };

  const renderPagination = () => {
    const first = 1;
    const firstResultN = (page - first) * perPage + first;
    const lengthN = (page - first) * perPage + data?.length;
    const text = `Showing ${firstResultN}-${lengthN} of ${total}`;

    return (
      <>
        <Button variant="outlined" color="primary" disabled={!canPrev} onClick={() => setPage(page - initialPage)}>Previous</Button>
        <Typography variant="body2">{text}</Typography>
        <Button variant="outlined" color="primary" disabled={!canNext} onClick={() => setPage(page + initialPage)}>Next</Button>
      </>
    );
  };

  return (
    <>
      <Box className={classes.resultContainer}>
        <Typography variant="h2">
          {isDetail ? t('cards.history.list.title') : `Results for ${code}`}
        </Typography>
        {isDetail === 'cards' && (
          <ListButton to="/cards" variant="contained" className={classes.buttonContainer} />
        )}
        {loading && <CircularProgress size={24} />}
        {!loading && !data?.length && <Typography variant="body2">No results found</Typography>}
        {!loading && data?.length > zero && (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('cards.history.list.code')}</TableCell>
                    <TableCell>{t('cards.history.list.createdAt')}</TableCell>
                    <TableCell>{t('cards.history.list.deletedAt')}</TableCell>
                    <TableCell>{t('cards.history.list.type')}</TableCell>
                    <TableCell>{t('cards.history.list.user')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map(row => (
                    <TableRow key={row.id}>
                      <TableCell>
                        {renderCardCell(row)}
                      </TableCell>
                      <TableCell>{row?.createdAt ? moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss') : ''}</TableCell>
                      <TableCell>{row?.deletedAt ? moment(row.deletedAt).format('DD/MM/YYYY HH:mm:ss') : ''}</TableCell>
                      <TableCell>{row?.type}</TableCell>
                      <TableCell>
                        {renderUserCell(row)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.pagination}>
              {renderPagination()}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default HistoricSearchUi;
