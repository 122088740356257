import React from 'react';
import { Title, useTranslate } from 'react-admin';

const PickupPointTitle = ({ record }) => {
  const t = useTranslate();

  return (
    <Title title={t('pickupPoint.title', { name: record.name })} />
  );
};

export default PickupPointTitle;
