import PickupPointIcon from '@material-ui/icons/Store';
import PickupPointCreate from './PickupPointCreate';
import PickupPointEdit from './PickupPointEdit';
import PickupPointList from './PickupPointList';

export default {
  list: PickupPointList,
  create: PickupPointCreate,
  edit: PickupPointEdit,
  icon: PickupPointIcon,
};
