import React from 'react';
import { SelectInput, useDataProvider } from 'react-admin';

const optionRenderer = choice => `${choice.code} - ${choice.name}`;

const PickupLocationInput = (props) => {
  const { record } = props;
  const dataProvider = useDataProvider();
  const [pickupLocations, setPickupLocations] = React.useState(
    record?.pickupLocationId ? [{ id: record.pickupLocationId }] : [],
  );

  React.useEffect(() => {
    let isMounted = true;

    dataProvider.getList('pickupLocations', {
      pagination: { page: 1, perPage: 200 },
    }).then(({ data }) => isMounted && setPickupLocations(data));

    return () => {
      isMounted = false;
    };
  }, [dataProvider]);

  return (
    <SelectInput
      {...props}
      choices={pickupLocations}
      optionText={optionRenderer}
      optionValue="id"
    />
  );
};

export default PickupLocationInput;
