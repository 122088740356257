import React, { useState } from 'react';
import {
  required, SaveButton, SimpleForm, Toolbar, useDataProvider, useNotify, useTranslate,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import PickupLocationInput from '../../pickupPoints/PickupLocationInput';
import PickupByNameInput from '../../pickupPoints/PickupByNameInput';


const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
const validateCardCreation = (values) => {
  const errors = {};
  if (!values.pickupLocationId) {
    errors.pickupLocationId = 'ra.validation.required';
  }
  if (!values.pickupPoint) {
    errors.pickupPoint = 'ra.validation.required';
  }
  return errors;
};
const AddLocation = ({ record, setLocation }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [pickupLocationId, setPickupLocationId] = useState(null);

  const { id } = record;
  const save = async (values) => {
    const { pickupPoint } = values;
    const input = { pickupPointIds: [pickupPoint], userId: id };
    try {
      const response = await dataProvider.create('userPickupPoints', { data: input });
      if (response) {
        const { data } = response;
        notify(`${translate('user.pickupLocation.success')}${id}`, { type: 'success' });
        setLocation(data);
      }
    } catch (error) {
      notify(`${translate('user.pickupLocation.error')}`, { type: 'error' });
    }
  };

  return (
    <SimpleForm save={save} toolbar={<CustomToolbar />} validate={validateCardCreation}>
      <Typography variant="h2" component="h2">
        {translate('user.pickupLocation.addTitle')}
      </Typography>
      <PickupLocationInput onChange={(e) => { setPickupLocationId(e.target.value); }} fullWidth source="pickupLocationId" validate={[required()]} />
      {pickupLocationId && (
      <PickupByNameInput
        fullWidth
        source="pickupPoint"
        pickupLocationId={pickupLocationId}
        validate={[required()]}
      />
      )}
    </SimpleForm>
  );
};

export default AddLocation;
