import React from 'react';
import { SelectInput, useDataProvider } from 'react-admin';

const optionRenderer = choice => `${choice.name}`;

const PickupByNameInput = (props) => {
  const { pickupLocationId } = props;
  const dataProvider = useDataProvider();
  const [pickupPoints, setPickupPoints] = React.useState([]);

  React.useEffect(() => {
    let isMounted = true;

    dataProvider.getList('pickupPoints', {
      pagination: { page: 1, perPage: 200 },
      filter: { pickupLocationIds: [pickupLocationId] },
    }).then(({ data }) => isMounted && setPickupPoints(data));

    return () => {
      isMounted = false;
    };
  }, [dataProvider, pickupLocationId]);

  return (
    <SelectInput
      {...props}
      choices={pickupPoints}
      optionText={optionRenderer}
      optionValue="id"
    />
  );
};

export default PickupByNameInput;
