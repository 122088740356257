import React from 'react';
import {
  Filter,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin';
import { statusChoices } from './choices';

const PickupPointFilter = ({ ...props }) => {
  const t = useTranslate();

  return (
    <Filter {...props}>
      <TextInput source="q" label={t('pickupPoint.list.filter.search')} alwaysOn />
      <SelectInput source="status" label={t('resources.pickupPoints.fields.status')} alwaysOn choices={statusChoices} />
    </Filter>
  );
};

export default PickupPointFilter;
