import React, { useState } from 'react';
import {
  Button, Confirm, SimpleForm, TextInput, Toolbar, useDataProvider, useNotify, useTranslate,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import IconSave from '@material-ui/icons/Delete';


const ShowLocation = ({ point, setLocation, record }) => {
  const { id } = record;
  const notify = useNotify();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const save = async () => {
    try {
      const response = await dataProvider.delete('userPickupPoints', { id });
      if (response) {
        notify(`${translate('user.pickupLocation.delete')}${id}`, { type: 'success' });
        setLocation(null);
      }
    } catch (error) {
      if (error && error.message) {
        notify(`Error: ${error.message}`, { type: 'error' });
      } else {
        notify(`${translate('user.cards.error')}`, { type: 'error' });
      }
    }
  };

  const CustomToolbar = props => (
    <Toolbar {...props}>
      <Button
        variant="contained"
        color="red"
        label="Delete Pickup Point"
        style={{ maxWidth: '180px', backgroundColor: 'red' }}
        onClick={() => { setOpen(true); }}
        startIcon={<IconSave />}
      />
    </Toolbar>
  );

  return (
    <div style={{ width: '50%' }}>
      <Confirm
        isOpen={open}
        title=""
        content={translate('user.pickupLocation.confirm')}
        onConfirm={save}
        onClose={() => setOpen(false)}
      />
      <SimpleForm record={point} save={save} toolbar={<CustomToolbar />}>
        <Typography variant="h2" component="h2">
          {translate('user.pickupLocation.deleteTitle')}
        </Typography>
        <TextInput
          options={{ disabled: true }}
          source="name"
          label={translate('user.pickupLocation.name')}
          fullWidth
        />
      </SimpleForm>
    </div>
  );
};

export default ShowLocation;
