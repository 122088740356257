import * as React from 'react';
import {
  Create,
  DateTimeInput,
  regex,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import moment from 'moment';
import PickupLocationInput from './PickupLocationInput';
import { parseDateFromQuery } from '../../lib/utils/date';
import { statusChoices, typeChoices } from './choices';
import { postalCodeRegex, decimalRegex, validateForm } from './rules';

const parseDate = value => (value ? moment(value).format() : null);

const PickupPointCreate = props => (
  <Create {...props} redirect="edit">
    <SimpleForm validate={validateForm}>
      <TextInput source="name" validate={required()} />
      <SelectInput source="status" choices={statusChoices} defaultValue={0} />
      <SelectInput source="type" choices={typeChoices} validate={[required()]} />
      <TextInput source="providerId" validate={[required()]} />
      <TextInput source="address.line1" validate={[required()]} />
      <TextInput source="address.line2" />
      <TextInput source="address.postalCode" validate={[required(), regex(postalCodeRegex)]} />
      <TextInput source="address.city" validate={[required()]} />
      <PickupLocationInput source="pickupLocationId" validate={[required()]} />
      <TextInput source="geoLocation.latitude" validate={[regex(decimalRegex)]} />
      <TextInput source="geoLocation.longitude" validate={[regex(decimalRegex)]} />
      <DateTimeInput source="promotionsAvailabilityDate" parse={parseDate} />
      <TextInput source="createdAt" disabled defaultValue={parseDateFromQuery(new Date().toISOString())} />
    </SimpleForm>
  </Create>
);

export default PickupPointCreate;
