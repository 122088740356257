import React from 'react';
import {
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { statusChoices } from './choices';

const renderRecord = (t, field, value) => ({
  ...(value !== undefined) && {
    status: t(statusChoices.find(choice => choice.id === value).name),
  },
});

const StatusField = ({ permissions, ...props }) => {
  const t = useTranslate();
  const record = useRecordContext();
  const { source } = props;

  return (
    <TextField {...props} record={renderRecord(t, source, record[source])} />
  );
};

export default StatusField;
