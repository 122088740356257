import React from 'react';
import {
  Datagrid,
  List,
  TextField,
} from 'react-admin';
import StatusField from './StatusField';
import PickupPointFilter from './PickupPointFilter';

const PickupPointList = ({ permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<PickupPointFilter />}
    exporter={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" sortable={false} />
      <StatusField source="status" sortable={false} />
    </Datagrid>
  </List>
);

export default PickupPointList;
