import React from 'react';
import {
  DeleteWithConfirmButton,
  SaveButton,
  Toolbar,
  useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  override: {
    '&.MuiToolbar-root': {
      justifyContent: 'space-between',
    },
  },
}));

const PickupPointToolbar = (props) => {
  const { record } = props;
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Toolbar {...props} className={classes.override}>
      <SaveButton />
      <DeleteWithConfirmButton mutationMode="pessimistic" confirmTitle={t('pickupPoint.title', { name: record.name })} />
    </Toolbar>
  );
};

export default PickupPointToolbar;
