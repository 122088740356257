import _ from 'lodash';

export const postalCodeRegex = /^\d{5}$/;
export const decimalRegex = /^-?\d+(\.\d+)?$/;

const validateGeoLocation = (geoLocation) => {
  const errors = {};

  if (geoLocation) {
    const latitude = _.get(geoLocation, 'latitude');
    const longitude = _.get(geoLocation, 'longitude');

    if (latitude && !longitude) {
      errors.longitude = 'pickupPoint.form.validation.requiredLongitude';
    }

    if (longitude && !latitude) {
      errors.latitude = 'pickupPoint.form.validation.requiredLatitude';
    }
  }

  return errors;
};

export const validateForm = values => ({
  geoLocation: validateGeoLocation(_.get(values, 'geoLocation')),
});
