import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

const SESSION_UUID = `session-uuid_${process.env.REACT_APP_ID}`;

const getSessionUuid = () => sessionStorage.getItem(SESSION_UUID);
export const setSessionUuid = value => sessionStorage.setItem(SESSION_UUID, value);

export const getSessionKey = () => {
  if (getSessionUuid()) {
    return getSessionUuid();
  }

  const key = uuidv4();
  setSessionUuid(key);

  return key;
};
